<template>
  <div class="home">
    <!-- <button @click="url">123</button> -->
    <div class="banner">
      <div class="bannerTop">
        <div class="icon">汇莱汽车科技</div>
        <div class="nav">
          <div class="title">网络技术</div>
          <div class="title">人工智能</div>
          <div class="title">软件开发</div>
          <div class="title">行业方案</div>
          <div class="title">营销案例</div>
          <div class="title">技术支持</div>
        </div>
      </div>

      <div class="mid">科技服务未来</div>
      <div class="mid2">未来已来，引领汽车科技行业新时代</div>
    </div>
    <div class="content">
      <div class="box">
        <div class="img"></div>
        <div class="cont">
          <div class="text1">网络技术服务</div>
          <div class="text2">
            提供实现数据传输、通信、交流和协同工作的各种基础设施和工具。
          </div>
        </div>
      </div>
      <div class="box">
        <div class="img"></div>
        <div class="cont">
          <div class="text1">人工智能服务</div>
          <div class="text2">
            利用人工智能技术，为用户提供具有智能决策、预测和分析能力的计算机服务。
          </div>
        </div>
      </div>
      <div class="box">
        <div class="img"></div>
        <div class="cont">
          <div class="text1">软件开发服务</div>
          <div class="text2">
            提供从需求分析、设计、编码、测试到部署等全流程的开发服务。
          </div>
        </div>
      </div>
      <div class="box">
        <div class="img"></div>
        <div class="cont">
          <div class="text1">广告营销服务</div>
          <div class="text2">
            提供推广策划、市场调研、广告创意、媒体投放等一系列营销服务，以提高企业知名度和市场竞争力。
          </div>
        </div>
      </div>
      <div class="box">
        <div class="img"></div>
        <div class="cont">
          <div class="text1">系统集成服务</div>
          <div class="text2">集成ERP系统、创建订单、数据查询。</div>
        </div>
      </div>
    </div>
    <div style="width: 500px; margin: 0 auto; padding: 20px 0">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51015602000597"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
        ><img src="../assets/beian.png" style="float: left" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
        >
          川公网安备 51015602000597号
        </p></a
      >
      <a
        target="_blank"
        href="http://beian.miit.gov.cn"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
        >
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
        >
     蜀ICP备2023037656号
        </p></a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  methods: {
    url() {
      this.$router.push("/link");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;

  .banner {
    width: 100%;
    background-image: url("../assets/banner.png");
    background-size: cover;
    height: 520px;

    .bannerTop {
      display: flex;
      width: 100%;
      .icon {
        margin-top: 12px;
        margin-left: 22.5%;
        font-size: 26px;
        font-family: BlinkMacSystemFont, Helvetica, "lucida Grande",
          "PingFang SC", SCHeiti, "Microsoft YaHei";
        cursor: pointer;
        color: #437fec;
      }
      .nav {
        // border: 1px solid red;
        padding: 20px;
        margin-left: 100px;
        width: 30%;
        display: flex;
        justify-content: space-around;

        .title {
          font-family: BlinkMacSystemFont, Helvetica, "lucida Grande",
            "PingFang SC", SCHeiti, "Microsoft YaHei";
          cursor: pointer;
          color: #437fec;
          font-size: 14px;
          &:hover {
            transition: 0.5s;
            color: #79a2ee;
          }
        }
      }
    }

    .mid {
      padding-top: 100px;
      color: #437fec;
      font-size: 54px;
      letter-spacing: 15px;
      margin: auto;
      width: 55%;
    }
    .mid2 {
      padding-top: 15px;
      color: #437fec;
      font-size: 14px;
      font-family: BlinkMacSystemFont, Helvetica, "lucida Grande", "PingFang SC",
        SCHeiti, "Microsoft YaHei";
      margin: auto;
      width: 55%;
    }
  }
  .content {
    width: 55%;
    margin: 75px auto 0px;
    display: flex;
    justify-content: space-between;
    .box {
      .img {
        width: 120px;
        height: 120px;
        background-image: url("../assets/wljs.png");
        background-size: cover;
      }
      &:nth-child(2) {
        .img {
          background-image: url("../assets/rgzn.png");
        }
      }
      &:nth-child(3) {
        .img {
          background-image: url("../assets/rjkf.png");
        }
      }
      &:nth-child(4) {
        .img {
          background-image: url("../assets/ggyx.png");
        }
      }
      &:nth-child(5) {
        .img {
          background-image: url("../assets/xtjc.png");
        }
      }
      .cont {
        width: 120px;
        .text1 {
          margin-top: 20px;
          font-size: 20px;
          font-weight: 500;
          color: #3e3a39;
          margin-bottom: 10px;
          text-align: center;
          font-family: BlinkMacSystemFont, Helvetica, "lucida Grande",
            "PingFang SC", SCHeiti, "Microsoft YaHei";
        }
        .text2 {
          text-align: justify;
          line-height: 24px;
          font-size: 13px;
          color: #898989;
        }
      }
    }
  }
}
</style>
