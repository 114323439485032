<template>
  <div class="home">
    <div class="item" @click="tourl(1)">
      <img class="img" src="../assets/广联改版.png" alt="" />
      <span>数字无忧卡（广联）</span>
    </div>
    <div class="item" @click="tourl(2)">
      <img class="img" src="../assets/国寿改版.png" alt="" />
      <span>数字无忧卡（国寿财）</span>
    </div>
    <div class="item" @click="tourl(3)">
      <img class="img" src="../assets/双保.png" alt="" />
      <span>车险系统（易保云平台）</span>
    </div>
    <div class="item" @click="tourl(4)">
      <img class="img" src="../assets/e保.png" alt="" />
      <span>双保系统（易保）</span>
    </div>
    <div class="item" @click="tourl(5)">
      <img class="img" src="../assets/综合入口.png" alt="" />
      <span>新能源延保系统</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "link",
  components: {},
  methods: {
    tourl(number) {
      if (number == 1) {
        window.location.href = "https://gps.cgacar.com/web-dj-site/login";
      }
      if (number == 2) {
        window.location.href = "https://cx.qcserve.com/admin/login.jsp";
      }
      if (number == 3) {
        window.location.href =
          "https://grandauto.ebaocloud.com.cn/pa/caris/desktop/#/login";
      }
      if (number == 4) {
        window.location.href = "http://210.51.57.44/insurance/main.jsp";
      }
      if (number == 5) {
        window.location.href = "https://xcx.autocare168.cn/";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  width: 100%;
  .item {
    display: flex;
    align-items: center;
    text-align: center;
    width: 150px;
    height: 50px;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 20px;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 8px 3px 10px #aaa;

    img {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
